import { toDisplayString as _toDisplayString, vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0a388632"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "absolu_bottom"
};
const _hoisted_2 = {
  class: "spacing"
};
const _hoisted_3 = {
  class: "flex_bg"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_withDirectives(_createElementVNode("div", {
    onClick: _cache[0] || (_cache[0] = (...args) => $options.close && $options.close(...args)),
    class: "flex_box",
    style: _normalizeStyle({
      'font-size': $props.content.leftSize + 'px',
      color: $props.content.leftNmaeColor,
      'background-color': $props.content.leftButtomColor,
      'border-color': $props.content.leftButtomBorderColor,
      'border-radius': $props.content.leftButtomSize + 'px'
    })
  }, _toDisplayString($props.content.leftNmae), 5), [[_vShow, $props.content.leftShow]]), _withDirectives(_createElementVNode("div", {
    onClick: _cache[1] || (_cache[1] = (...args) => $options.qure && $options.qure(...args)),
    class: "flex_box flex_box_left",
    style: _normalizeStyle({
      'font-size': $props.content.rightSize + 'px',
      color: $props.content.rightNmaeColor,
      'background-color': $props.content.rightButtomColor,
      'border-color': $props.content.rightButtomBorderColor,
      'border-radius': $props.content.rightButtomSize + 'px'
    })
  }, _toDisplayString($props.content.rightNmae), 5), [[_vShow, $props.content.rightShow]])])])]);
}
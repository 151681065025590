import { $on, $off, $once, $emit } from '../../../utils/gogocodeTransfer';
import "swiper/dist/css/swiper.css"; //在全局没引入，这里记得要！
import { swiper, swiperSlide } from 'vue-awesome-swiper';
export default {
  props: ['content', 'hrefs'],
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      arrs: [],
      shake: false,
      swiperOption: {
        autoplay: false,
        //自动切换的时间间隔 0不启用
        loop: true,
        effect: 'slide',
        pagination: '.swiper-pagination',
        paginationClickable: true,
        on: {
          click: function (e) {
            this.openpage(e);
          }
        }
      }
    };
  },
  created() {
    for (let i = 0; i < this.content.imgs.length; i++) {
      if (this.content.imgs[i].type == '1') {
        this.content.imgs[i].href = '#' + this.hrefs[this.content.imgs[i].linkAnchor].hrefid;
      }
    }
    // 有图片获取图片设置
    if (this.content.imgs.length) {
      // 设置自动轮播时间
      this.swiperOption.autoplay = Number(this.content.interval * 1000);
      // 设置轮播滚动方式(可设置为"fade"（淡入）"cube"（方块）"coverflow"（3d流）"flip"（3d翻转）) 默认为slide平滑
      let arrs = ['', 'slide', 'flip', 'coverflow', 'fade', 'cube'];
      this.swiperOption.effect = arrs[this.content.rotation];
    }
  },
  methods: {
    openpage(e) {
      let index = this.content.imgs.findIndex(i => i.img === e.target.attributes.src.nodeValue);
      let obj = this.content.imgs[index];
      if (this.shake) return;
      this.shake = true;
      setTimeout(() => {
        this.shake = false;
      }, 2000);
      if (obj.type == '0') {
        //链接地址
        if (obj.linkUrl != '') window.location.href = obj.linkUrl;
      } else if (obj.type == '2') {
        //打开弹窗
        if (obj.popup != '') $emit(this, 'arousePop', obj.popup);
      } else if (obj.type == '3' || obj.type == '4') {
        //打开小程序
        $emit(this, 'openWechat', obj.appletlink, obj.appletUrl, obj.applet, obj.appletType);
      }
    }
  },
  emits: ['arousePop', 'openWechat']
};
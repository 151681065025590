import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8123696c"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "suspensionAssembly",
    onClick: _cache[0] || (_cache[0] = $event => $options.openpage(_ctx.item))
  }, [_createElementVNode("div", {
    class: "relative",
    style: _normalizeStyle({
      'margin-top': $props.content.topMargin + 'px',
      'padding-left': $props.content.leftMargin + 'px',
      'padding-right': $props.content.rightMargin + 'px',
      'margin-bottom': $props.content.bottomMargin + 'px',
      left: $data.clleft + 'px',
      top: $data.cltop,
      height: $data.height + 'px'
    })
  }, [_createElementVNode("img", {
    class: "img",
    src: $props.content.popimg,
    style: _normalizeStyle({
      width: $data.width + 'px'
    })
  }, null, 12, _hoisted_1)], 4)]);
}
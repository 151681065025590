import { $on, $off, $once, $emit } from '../../../utils/gogocodeTransfer';
import * as Vue from 'vue';
export default {
  props: {
    content: Object
  },
  data() {
    return {
      shake: false
    };
  },
  methods: {
    close() {
      if (this.content.leftisclick) {
        if (this.shake) return;
        this.shake = true;
        setTimeout(() => {
          this.shake = false;
        }, 1000);
        if (this.content.leftlinkType == '0') {
          //跳转
          if (this.content.leftwholeType == '0') {
            //网页跳转
            window.location.href = this.content.leftwebpageUrl;
          } else if (this.content.leftwholeType == '2') {
            //打开小程序
            $emit(this, 'openWechat', this.content.leftwholeApplet, this.content.leftwholeAppletType);
          }
        } else if (this.content.leftlinkType == '2') {
          //拨打电话
          window.location.href = 'tel:' + this.content.lefttelephone;
        }
      }
    },
    qure() {
      if (this.content.rightisclick) {
        if (this.shake) return;
        this.shake = true;
        setTimeout(() => {
          this.shake = false;
        }, 1000);
        if (this.content.rightlinkType == '0') {
          //跳转
          if (this.content.rightwholeType == '0') {
            //网页跳转
            window.location.href = this.content.rightwebpageUrl;
          } else if (this.content.rightwholeType == '2') {
            //打开小程序
            $emit(this, 'openWechat', this.content.rightwholeApplet, this.content.rightwholeAppletType);
          }
        } else if (this.content.rightlinkType == '2') {
          //拨打电话
          window.location.href = 'tel:' + this.content.righttelephone;
        }
      }
    }
  },
  emits: ['openWechat']
};